const namespace = 'workflows.flow_actions.';

const translations = {
    areYouSure: `${namespace}are_you_sure`,
    cancel: 'workflows.common.cancel',
    cannotAmendFlow: `${namespace}cannot_amend_flow`,
    completed: `${namespace}completed`,
    confirmCompletion: `${namespace}confirm_completion`,
    confirmSubmission: `${namespace}confirm_submission`,
    finishLater: `${namespace}finish_later`,
    markComplete: `${namespace}mark_complete`,
    startFlow: `${namespace}start_flow`,
    submitForReview: `${namespace}submit_for_review`,
};

export { translations };
