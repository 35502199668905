<template>
    <Fragment>
        <div class="flow-actions" data-qa="flow-actions">
            <VButton
                v-if="!hasStarted"
                alt
                block
                class="flow-action"
                color="primary"
                data-qa="start-flow"
                :disabled="isCalculating"
                name="Start Flow"
                @click="!isCalculating && $emit('begin', currentFlow.id)"
            >
                {{ translator.t(t.startFlow) }}
            </VButton>

            <VButton
                v-if="isUnfinished"
                alt
                block
                class="flow-action"
                color="primary"
                data-qa="finish-flow-later"
                name="Finish Flow Later"
                @click="$emit('postpone', currentFlow.id)"
            >
                {{ translator.t(t.finishLater) }}
            </VButton>

            <VButton
                v-else-if="isFinished"
                ref="completeWorkflowRef"
                alt
                block
                class="flow-action"
                color="primary"
                data-qa="flow-finished"
                :name="currentFlow.reviewerId ? translator.t(t.submitForReview) : translator.t(t.markComplete)"
                @click="isCompleteFlowVisible = true"
            >
                {{ currentFlow.reviewerId ? translator.t(t.submitForReview) : translator.t(t.markComplete) }}
            </VButton>

            <FirstReviewActions
                v-else-if="shouldShowFirstReviewActions"
                :current-flow="currentFlow"
                data-qa="first-review-actions"
                :user-id="userId"
                @finish="isFinishReviewVisible = true"
                @hand-back="isHandbackVisible = true"
            />

            <SecondReviewActions
                v-else-if="shouldShowSecondReviewActions"
                :current-flow="currentFlow"
                data-qa="second-review-actions"
                :user-id="userId"
                @finish="isFinishReviewVisible = true"
                @hand-back="isHandbackVisible = true"
            />

            <SingleReviewActions
                v-else-if="shouldShowSingleReview"
                :current-flow="currentFlow"
                data-qa="single-review-actions"
                :user-id="userId"
                @finish="isFinishReviewVisible = true"
                @hand-back="isHandbackVisible = true"
            />

            <VButton
                v-else-if="shouldShowMarkCompleted"
                alt
                block
                class="flow-action"
                color="primary"
                data-qa="flow-finished"
                :name="translator.t(t.markComplete)"
                @click="isCompleteFlowVisible = true"
            >
                {{ translator.t(t.markComplete) }}
            </VButton>

            <VButton v-else alt color="primary" data-qa="flow-complete" disabled name="Flow already completed">
                {{ translator.t(t.completed) }}
            </VButton>
        </div>

        <Modal
            v-model="isCompleteFlowVisible"
            name="Confirm Flow Mark Complete"
            @confirm="$emit('complete', currentFlow.id)"
        >
            <ModalHeader>{{ translator.t(t.areYouSure) }}</ModalHeader>
            <ModalContent>
                {{
                    translator.t(t.cannotAmendFlow, {
                        status: currentFlow && currentFlow.reviewerId ? 'submitted' : 'completed',
                    })
                }}
            </ModalContent>
            <ModalFooter
                :cancel-button-text="translator.t(t.cancel)"
                :confirm-button-text="
                    translator.t(currentFlow && currentFlow.reviewerId ? t.confirmSubmission : t.confirmCompletion)
                "
            />
        </Modal>

        <Modal
            v-model="isFinishReviewVisible"
            name="Confirm Flow Review Complete"
            @confirm="$emit('finish-review', currentFlow.id)"
        >
            <ModalHeader>{{ translator.t(t.areYouSure) }}</ModalHeader>
            <ModalContent>{{ translator.t(t.cannotAmendFlow, { status: 'completed' }) }}</ModalContent>
            <ModalFooter
                :cancel-button-text="translator.t(t.cancel)"
                :confirm-button-text="translator.t(t.confirmCompletion)"
            />
        </Modal>

        <HandBackModal v-if="Boolean(currentFlow)" v-model="isHandbackVisible" :flow="currentFlow" />
    </Fragment>
</template>

<script>
import { computed, defineComponent, ref } from 'vue';
import { Fragment } from 'vue-frag';
import PropTypes from 'vue-types';

import { Modal, ModalContent, ModalFooter, ModalHeader } from '@/components/Modal';
import { useProductTourTemplateRefs } from '@/composables/tours/useProductTourTemplateRefs';
import { useVueI18n } from '@/composables/useVueI18n';
import useXavierGlobals from '@/hooks/useXavierGlobals';
import HandBackModal from '@/layout/ClientLayout/Modals/HandBack.vue';

import FirstReviewActions from './FirstReview.vue';
import { translations as t } from './FlowActions.translations';
import SecondReviewActions from './SecondReview.vue';
import SingleReviewActions from './SingleReview.vue';

export default defineComponent({
    name: 'FocusSidebarFlowActions',

    components: {
        FirstReviewActions,
        SecondReviewActions,
        SingleReviewActions,
        Fragment,
        HandBackModal,
        Modal,
        ModalContent,
        ModalHeader,
        ModalFooter,
    },

    props: {
        currentClient: PropTypes.object.isRequired,
        currentFlow: PropTypes.object.isRequired,
        isCalculating: PropTypes.bool.isRequired,
    },

    emits: ['begin', 'complete', 'finish-review', 'postpone'],

    setup(props) {
        const handBackModalRef = ref(null);
        const { userId } = useXavierGlobals();
        const { completeWorkflowRef } = useProductTourTemplateRefs();
        const translator = useVueI18n();

        const isCompleteFlowVisible = ref(false);
        const isFinishReviewVisible = ref(false);
        const isHandbackVisible = ref(false);

        /**
         * How many checks are left for the user to complete.
         *
         * @returns {Integer}
         */
        const checksToComplete = computed(() => {
            return props.currentFlow ? props.currentFlow.totalChecks - props.currentFlow.completedChecks : 0;
        });

        /**
         * Has the current flow been started.
         *
         * @returns {Boolean}
         */
        const hasStarted = computed(() => props.currentFlow.started ?? false);

        /**
         * Has the current flow been finished.
         *
         * @returns {Boolean}
         */
        const isFinished = computed(() => !props.currentFlow.completed && checksToComplete.value < 1);

        /**
         * Is the current flow in progress.
         *
         * @returns {Boolean}
         */
        const isUnfinished = computed(() => !props.currentFlow.completed && checksToComplete.value > 0);

        /**
         * Are there multiple reviewers and should we show the actions for the
         * first reviewer of the flow.
         *
         * @returns {Boolean}
         */
        const shouldShowFirstReviewActions = computed(() => {
            return (
                props.currentFlow.completed &&
                Boolean(props.currentFlow.secondReviewerId) &&
                !props.currentFlow.reviewed
            );
        });

        /**
         * Are there multiple reviewers and should we show the actions for the
         * second reviewer of the flow.
         *
         * @returns {Boolean}
         */
        const shouldShowSecondReviewActions = computed(() => {
            return (
                props.currentFlow.completed &&
                Boolean(props.currentFlow.secondReviewerId) &&
                props.currentFlow.reviewed &&
                !props.currentFlow.secondReview
            );
        });

        /**
         * If there is only one reviewer of the flow, should we show the single
         * reviewer actions.
         *
         * @returns {Boolean}
         */
        const shouldShowSingleReview = computed(() => {
            return (
                props.currentFlow.completed &&
                Boolean(props.currentFlow.reviewerId) &&
                !props.currentFlow.secondReviewerId &&
                !props.currentFlow.reviewed
            );
        });

        //handles case where flow is completed, but the status remains under review
        const shouldShowMarkCompleted = computed(() => {
            return (
                isFinished.value &&
                Boolean(props.currentFlow.completed) &&
                !props.currentFlow.secondReviewerId &&
                !props.currentFlow.secondReview &&
                props.currentFlow.status === 'Under Review'
            );
        });

        return {
            completeWorkflowRef,
            handBackModalRef,
            hasStarted,
            isCompleteFlowVisible,
            isFinishReviewVisible,
            isFinished,
            isHandbackVisible,
            isUnfinished,
            shouldShowFirstReviewActions,
            shouldShowMarkCompleted,
            shouldShowSecondReviewActions,
            shouldShowSingleReview,
            t,
            translator,
            userId,
        };
    },
});
</script>

<style lang="scss" scoped src="./flow-actions.scss"></style>
